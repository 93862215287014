<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <div class="va-card">
          <div class="row">
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <h4 class="menu_header">Vouchers Payments</h4>
                </div>
                <div class="search_section">
                  <div class="row">
                    <div class="col-md-5 col-sm-12">
                      <div class="form-group has-search mt-1">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="search" @keyup="searchPayments" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..."   class="form-control" >
                      </div>
                    </div>

                    <div class="col-md-3">
                      <select class="form-control" id=""  v-model="paymentStatus" @change="searchPayments()"> 
                        <option value=""> --Filter Payment status -- </option>
                        <option value="COMPLETED">Completed</option>
                        <option value="FAILED"> Failed</option>
                        <option value="PENDING">Pending</option>
                        <option value="PLACED">Placed</option>
                      </select>
                    </div>
                    <div class="col-md-2 col-sm-12">
                      <div class="form-group has-search">
                        <button class="btn btn_reserveport_orange" @click="searchPayments()">search</button>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
              <hr>
            </div>
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="review_table">
                  <div class="table_section">
                    <table class="table  table-hover">
                      <thead>
                        <th scope="col">Giftcard Reference</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Amount({{ pesapalConfig.currency_code }})</th>
                        <th scope="col">Payment Details</th>
                        <th scope="col">Date</th>
                      </thead>
                      <tbody v-if="!loading">
                        <tr v-for="payment in payments" :key="payment.id">
                          <td>
                            <span class="title_text">
                              {{payment.voucher_reference ? payment.voucher_reference : "N/A"}}
                            </span> </td>
                          <td>{{payment.payer}}</td>
                          <td>{{payment.payer_email ? payment.payer_email : "N/A"}}</td>
                          <td>{{payment.phone ? payment.phone : "N?A"}}</td>
                          <td>{{payment.amount}}</td>
                          <td>
                            <div>
                              <strong>Status: </strong><span :class="[{'active': payment.status.toLowerCase() === 'completed'},{'disabled':payment.status.toLowerCase() === 'failed'},{'pending': payment.status.toLowerCase() === '' || payment.status.toLowerCase()  == 'pending' || payment.status.toLowerCase()  == 'placed'}]">
                                <strong>{{payment.status ? payment.status : 'PLACED'}}</strong>
                              </span>
                            </div>
                            
                            <br>
                            <div v-if="payment.method">
                              <strong>Method: </strong> <span>{{ payment.method }}</span>
                            </div>
                           
                    
                            <span v-if="payment.user_name">
                              Manually Completed By: <br> <b>{{payment.user_name}}</b><br>
                            </span>
                            <span v-if="payment.status !=='COMPLETED'">
                              <button class="btn btn-sm mt-1 mr-1 btn-default custom_btn custon_orange t_btn" @click="checkPaymentStatus(payment.voucher_reference)"><span v-if="spinner == payment.voucher_reference"><i class='fa fa-spinner fa-spin '></i></span> Check status</button>
                              <button class="btn btn-sm mt-1 btn-default custom_btn custon_orange t_btn" data-toggle="modal" :data-target="'#confirmation_m_'+payment.id">Manually Complete</button>
                            </span>
                            <!-- modal for confirmation of manually completion of payment -->
                            <div class="modal" tabindex="-1" role="dialog" :id="'confirmation_m_'+payment.id">
                              <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                                <form @submit.prevent="manuallCompletePayment(payment.id)">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <button @click="close()" :id="'btn_close'+payment.id" type="button" class="close" data-dismiss="modal" aria-label="Close" >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div class="modal-body" >
                                      <div v-if="!showDetails">
                                        <p>Are you sure you want to manually complete this payment. ?</p>
                                      </div>
                                      <div v-else>
                                        <input type="text" class="form-control" placeholder="Enter Payment Method" v-model="paymentMethod">
                                      </div>  
                                    </div>
                                    <div class="modal-footer">
                                      <button v-if="!showDetails" type="button" class="btn btn-secondary"  data-dismiss="modal">No</button>
                                      <button v-if="!showDetails" type="button" class="btn btn-primary" @click="showPaymentDetails()">Yes</button>
                                      <button v-if="showDetails" type="submit" class="btn btn-primary" ><span v-if="spinner == payment.id"><i class='fa fa-spinner fa-spin '></i></span> Submit</button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <!-- end modal -->
                          </td>
                          <td>
                            {{formatDate(payment.created_at)}}
                          </td>
                          
                        </tr>
                      </tbody>
                      <tfoot v-if="!loading">
                        <tr>
                          <td colspan="8">
                            <div id="pagination" v-if="totalPages > 1">
                              <ul class="showItems">
                                <li>Show Items:
                                  <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                    <option :value="10">10</option>
                                    <option :value="20">20</option>
                                    <option :value="30">30</option>
                                    <option :value="40">40</option>
                                    <option :value="50">50</option>
                                  </select>
                                </li>

                              </ul>
                              <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />

                            </div>

                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div v-if="loading" class="loading-cover">
                    <div class="loader"> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import Pagination from '@/pages/pagination/pagination.vue';
import { useToast } from "vue-toastification";
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
const toast = useToast();

export default {
    components: {
        Pagination,
    },
    data() {
        return {
            paymentStatus: '',
            payments: [],
            currency: '',
            search : '',
            loading: false,
            page: 1,
            totalPages: 0,
            totalRecords: 0,
            recordsPerPage: this.$store.state.voucherPaymentRecordPerPage,
            debounce: null,
            spinner: '',
            showDetails: false,
            paymentMethod: ''
        }
    },
    created: async function () {
        this.getPayments();
    },
    methods: {
      showPaymentDetails(){
        this.showDetails = !this.showDetails;
        // 
      },
        searchPayments() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.getPayments();
            }, 1500)
        },
        close(){
          this.showDetails = false;
        },
        async getPayments(){
           this.loading = true;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            let url = `${this.dashBaseUrl}/giftvoucher-payments/${this.merchantID}`;
            url = new URL(url);
            url.searchParams.set('page', this.page);
            url.searchParams.set('size', this.recordsPerPage);
            if(this.reviewquestion !=''){
              url.searchParams.set('payment_status',this.paymentStatus)
            }
            if(this.search !=''){
              url.searchParams.set('search',this.search);
            }
            try {
              // 
              const response = await this.axios.get(url, config)
              if(response.status == 200){
                this.payments = response.data.data.data
                this.totalPages = Math.ceil(response.data.data.total / this.recordsPerPage)
                this.totalRecords = response.data.data.total   
                this.loading = false
              }
            }catch(error){
              this.loading = false
              if(error.response.status === 401){
                    this.$router.push({
                        name: 'login'
                    });
              }
            }

        },
        async checkPaymentStatus(reference){
          try {
            this.spinner = reference;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const payload={
              'reference': reference,
              'merchant_id': this.merchantID
            }
            const url = `${this.dashBaseUrl}/check-payment-status`;
            const response = await this.axios.post(url,payload,config);
            if(response.status === 200){
              this.spinner =null;
              if(response.data.status){
                toast.success('payment status: ' + response.data.payment_status)
              }else{
                toast.success('payment status: PLACED')
              }
              this.getPayments();
            }
          } catch (error) {
             if(error.response.status === 400){
              toast.error(error.response.data.errors)
             }else if(error.response.status === 401){
                    this.$router.push({
                        name: 'login'
                    });
            }
            // 
          }

        },
        async manuallCompletePayment(id){
          try{
            this.spinner = id;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const payload={
              'method': this.paymentMethod,
            }
            const url = `${this.dashBaseUrl}/voucher-payment-complete/${id}`;
            const response = await this.axios.post(url,payload,config);
            if(response.status === 200){
              toast.success('completed successfully');
              this.getPayments();
            }
            this.close();
            document.getElementById('btn_close'+id).click();
            this.spinner = false
          } catch(error){
            if(error.response.status === 400){
              let errorms = '';
              for(let x=0;x< error.response.data.errors.length;x++){
                errorms = error.response.data.errors[x] + ','
              }
              toast.error(errorms);
              this.close();
              document.getElementById('btn_close'+id).click();
              this.spinner = false
            }else if(error.response.status === 401){
                    this.$router.push({
                        name: 'login'
                    });
             }
            
            // 
          }
        },
        onPageChange(page) {
            this.page = page
            this.getPayments();
        },
        onChangeRecordsPerPage() {
          const payload = {
                "type":"vouchers_payment",
                "size":this.recordsPerPage
            }
            this.$store.commit("setPageLimit",payload)
            this.getPayments();
        },

    },
    mixins: [ReusableDataMixins, ReusableFunctionMixins]
}
</script>
